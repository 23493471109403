import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import enUs from './locales/en-US/translation.json';
import svSe from './locales/sv-SE/translation.json';

import config from './i18n-config';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: enUs,
      },
      sv: {
        translation: svSe,
      },
    },

    fallbackLng: config.defaultLang,
    supportedLngs: config.langs,
    nonExplicitSupportedLngs: false,
    cleanCode: true,
    debug: process.env.NODE_ENV !== 'production',

    interpolation: {
      escapeValue: false,
    },

    react: {
      useSuspense: false,
    },
  });

export default i18n;
