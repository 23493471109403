import React from 'react';

// import './common.css';
import './frontend.css';
import MigratedStyles from './styles';

function Elementor() {
  return <MigratedStyles />;
}

export default Elementor;
