// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-faq-js": () => import("./../../../src/templates/faq.js" /* webpackChunkName: "component---src-templates-faq-js" */),
  "component---src-templates-front-page-js": () => import("./../../../src/templates/frontPage.js" /* webpackChunkName: "component---src-templates-front-page-js" */),
  "component---src-templates-package-category-js": () => import("./../../../src/templates/packageCategory.js" /* webpackChunkName: "component---src-templates-package-category-js" */),
  "component---src-templates-package-js": () => import("./../../../src/templates/package.js" /* webpackChunkName: "component---src-templates-package-js" */),
  "component---src-templates-packages-js": () => import("./../../../src/templates/packages.js" /* webpackChunkName: "component---src-templates-packages-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-category-js": () => import("./../../../src/templates/postCategory.js" /* webpackChunkName: "component---src-templates-post-category-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-posts-js": () => import("./../../../src/templates/posts.js" /* webpackChunkName: "component---src-templates-posts-js" */),
  "component---src-templates-staff-js": () => import("./../../../src/templates/staff.js" /* webpackChunkName: "component---src-templates-staff-js" */)
}

